.modalContent{
    display: flex;
    flex-direction: column;
    align-self: center;
    font-weight: 600;
    font-size: 0.95rem;
    width: 27rem;
    table.infoTable {
        border-collapse: collapse;
        width: 100%;
        tr {
          display: flex;
          padding-top: 0.6rem;
          td.category {
            width: 35%;
            padding-right: 2rem;
          }
          td.dot {
            width: 1%;
            padding-right: 1rem;
          }
          td.value{
            width: 64%;
          }
        }
    }
    .row2{
        display: flex;
        column-gap: 6rem;
        justify-content: center;
        padding-top: 1.5rem;
        color: #333;
        .col{
            display: flex;
            flex-direction: column;
            align-items: center;
            .value2{
                color: #6C74CA;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
    }
}