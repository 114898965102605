.reset {
    border: 1px solid #C6C6C6;
    background: #FFF;
    box-shadow: 4px 4px 10px 0px rgba(123, 122, 122, 0.15);
    border-radius: 1rem;
    padding: calc(1rem + 1vw);
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    
    .heading {
        align-self: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        color: #5a5a5a;
    }

    .head {
        font-size: calc(1rem + 0.8vw);
        text-align: center;
        font-weight: 600;
        color: #6C74CA;
    }

    p {
        font-size: 0.9rem;
        padding-left: 0.25rem;
        margin: 1.5rem 0rem 0rem 0rem;
        text-align: left;
    }

    input {
        border: none;
        padding: 0.75rem 1rem;
        margin-top: 0.75rem;
        color: #666;
        border: 1px solid #d2d1d1;
        border-radius: 6px;
        font-size: 0.9rem;
        font-weight: 400;
        width: calc(15rem + 10vw);
        max-width: 60vw;
        background: #FFF;
        box-shadow: 4px 4px 10px 0px rgba(123, 122, 122, 0.15);

        &:focus {
            outline: black;
        }
    }

}

button {
    border-radius: 3rem;
    background: #6C74CA;
    padding: 1rem 3rem;
    border: none;
    align-self: center;
    width: 10rem;
    color: white;
    margin-top: 2rem;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        background-color: #4e55a5;
    }
}

.field {
    display: flex;
    flex-direction: column;
    width: calc(15rem + 10vw);
    span {
        font-size: calc(0.8rem + 0.3vw);
        font-weight: 600;
        padding: calc(2rem + 1vw) 0rem 0.5rem 0rem;
    }

    p {
        margin: 0rem;
        color: #666;
        font-size: 0.9rem;
        text-align: center;
    }
}