.containerSlider {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 2rem;
  overflow: hidden;
  .slide {
    display: none;
  }
  .activeSlide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eff1f7;
    text-align: center;
    padding-bottom: 2rem;
    border: 1px solid #B9B9B9;
    border-radius: 40px;
    img {
      width: 60%;
      padding-top: 2rem;
    }
    span {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 10px;
      display: block;
      color : #636BC1
    }

    p {
      font-size: 0.9rem;
      margin-top: 10px;
      color: #666;
      padding: 0rem 2rem;
      max-width: 500px;
    }
  }

  .ContainerDots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 2.5rem;
    width: 100%;
    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #999;
      margin: 0 5px;
      cursor: pointer;
    }

    .activeDot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      background-color: #636bc1;
    }
  }
}
