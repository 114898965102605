.dashboard{
    display: flex;
    
    justify-content: flex-end;
    
    .teacherInfo{
        position: fixed;
        top:0;
        left:0;
        width: 325px;
        z-index: 10;
        // @media screen and (max-width : 768px) {
        //     display: none;
        // }
    } 
    .features{
        width: calc(100% - 350px);
        @media screen and (max-width : 768px) {
            width: 100%;
        }
    }
    .feedbackContainer{
      width: calc(100% - 350px);
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
        @media screen and (max-width : 768px) {
            width: 100%;
            
        }
        
    }
}