.feed_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #c1c1c1;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 2rem;
    margin: 10px;


    width: 100%;
    height: 100vh;
    

   
 

  }
  .feedbackContainer_Card {
    display: flex;
    flex-direction: column;
    width: 95%;
    gap: 25px;
    border: 2px solid #6368C0;
    border-radius: 10px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    margin-top: 20px;
  
  }
  
  .container_header {
    height: 40px;
    background-color: #eff1f7;
    width: 94%;
    border: 1px solid #c1c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
  }
  .container_header:hover {
    background-color: #6c74ca !important;
    color: #ffffff;
  
    border: 2px solid #c1c1c1;
  }
  .feedbackContainer_Card_button {
    text-align: center;
  
    padding: 20px;
  }
  .card_Container {
    border: 1px solid #c1c1c1;
    width: 95%;
    height: auto;
    background-color: #eff1f7;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .indexCount {
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px 5px;
    }
    gap: 5px;
  }
  
  .card_content {
    width: 95%;
    height: 100%;
    // border-left: 1px solid #c1c1c1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card_content_child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 95%;
    padding: 10px 5px;
  
    div {
      text-align: left;
  
      width: 100%;
      .cardHeadings {
        font-size: calc(1.2rem + 0.1vw);
        font-weight: 500;
        color: black;
      }
      .cardHeadingValues {
        font-size: calc(1.2rem + 0.1vw);
        font-weight: 400;
        margin-left: 10px;

        
      }
      .cardHeadingValues {
          font-size: calc(0.9rem + 0.1vw);
          font-weight: 400;
          margin-left: 10px;
        }
        
    }
    .subjectList {
      padding: 0px 8px;
    }
  }
  
  // feedbackform


  .delete {
    margin-left: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover {
      color: #ea4335;
    }
  }
  
  .feedbackform {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 10px;
    align-items: center;
    width: auto;
    gap: 20px;
    height: auto;
    
    overflow-y: auto;
    overflow-x: auto;

  }

  
  
  
  
  
  


  // -------------------------------------
  .containerFeedbackMain{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: auto;
    background-color: #c3c6e94b;
    font-family:'Arial';

font-size: 1rem;
    .backButtonContainer{
      width: 94%;
      display: flex;
      margin-top: 20px;
      border-radius: 10px;
      padding: 20px 5px;

      flex-direction: column;
      align-items: flex-start;
      border: 2px solid #6368C0;
      background-color: #fff;
      span{
        background-color: #47529a;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        font-size: 0.9rem;
      }


    }
    
  .card_container_buttons {
    min-width: 40px;
    height: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly !important;
    
    align-items: center;
    
    margin-right: 20px;
  
    padding-top: 0px;

    .buttonCard {
      font-size: 0.7rem;
      width: 80px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      text-align: center;
      border: 1px solid #353636;
      
      color: #ffffff;
      background-color: #5865b1;
      border-radius: 5px;
      cursor: pointer;
      &:hover{
          background-color: #47529a;
          color: #ffffff;
      }
  
    }
  }



  
  .viewDetailsContainer{
    height: 500px;
    width: auto;
    z-index: 20;
    position: absolute;
    background-color: #ffffff;
    top: 15%;
    font-size: 0.9rem;
    left: auto;
    border: 1px solid #353636;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 0px 50px;
    padding: 10px;
    
    overflow-x: hidden;
    p {
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      color: #070808;
    }
    overflow-y: auto;
    .cancel_container {
      display: flex;
      width: 90%;
      justify-content: end;
      padding: 15px;
    }
  .detailsContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    color: #020202;
    font-size: 0.9rem;
    width: 100%;
    .headerDiv{
      width: 70%;
text-align: left;
      .cardHeadings {
        font-weight: 500;
        color: black;
      }
      .cardHeadingValues {
        font-size: 0.9rem;
        font-weight: 400;
        margin-left: 10px;
      }
    
          }

 
  }



 
  




  }

  }


  .submitButton {
    width: 60%;
    padding: 20px 30px;
    display: flex;
    justify-content: space-around;
    .cancel {
      padding: 5px 20px;
      border: 1px solid black;
      border-radius: 10px;
      cursor: pointer;
      background-color: #ea4335;
      color: #ffffff;
      &:hover {
        background-color: #b32c20;
      }
    }
    
    .submit {
      padding: 5px 20px;
      border: 1px solid black;
      border-radius: 10px;
      cursor: pointer;
      background-color: #6c74ca;
      color: #ffffff;
      width: auto;
      &:hover {
        background-color: #47529a;
      }
    }

  }

  .addMemberContainer{
    border: 1px solid rgb(0, 0, 0);
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    z-index: 10;
    position: absolute;
    top: 20%;
  
    height: 60%;
    background-color: #FFF;
    .cancel_container{
      width: 100%;
      display: flex;
      justify-content: end;
      margin-right: 20px;
      margin-top: 10px;


    }
    

  }

  .inputContainer {
    width: 70%;
    display: flex;
    justify-content: space-between;
  }
  .inputheading {
    width: 40%;
    font-size: 0.9rem;
  }
  .inputfeild {
    border: 1px solid black;
    width: 40%;
    input {
      width: 100%;
      height: 20px;
    }
  }
  

  .dropdownselector {
    overflow-y: auto;
    overflow-x: auto;
    height: auto;
    max-height: 150px;
    z-index: 20;
    position: fixed;
    top: 50;
    width: 18.5%;
    border: 1px solid black;
  
    background-color: #ffffff;
    div {
      text-align: left;
      border: 1px solid black;
      padding: 2px 2px;
    }
    div:hover {
      background-color: #6c74ca;
      color: #ffffff;
      cursor: pointer;
    }
  }

   .tableContainer {
    width: auto;
    font-size: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-x: auto;
    overflow-y:auto ;
    margin-top: 15px;
    table {
      width: auto;
      border-collapse: collapse;
      overflow-x: auto;
      overflow-y: auto;
    }
    
    .tableHeader {
      background-color: #5e5b5b;
      color: #ccc;
    }
    
    .tableHeaderRow th {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
    }
    
    .tableBodyRow:hover {
      background-color: #f9f9f9;
    }
    
    .tableRowCell {
      text-align: left;
      border: 1px solid #ddd;
      padding: 8px;
    }
    
  
  
  }

  .addMemberContainerButton{
    width: 100%;
    display: flex;
    align-items: center;

    .submit {
      padding: 5px 20px;
      border-radius: 10px;
      width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
      cursor: pointer;
      background-color: #6c74ca;
      color: #ffffff;
    
      &:hover {
        background-color: #47529a;
      }

   
    }
  
  }
  .create_feedback_container {

    height: 500px;
    width: auto;
    z-index: 20;
    position: absolute;
    background-color: #ffffff;
    top: 15%;
    font-size: 0.9rem;
    left: auto;
    border: 1px solid #353636;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 0px 50px;
    padding: 10px;
    p {
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
    }
    .cancel_container{
      width: 100%;
      display: flex;
      justify-content: end;
      margin-right: 20px;
      margin-top: 20px;
    }
    .createTableContainer{
      width: autp;
      overflow-x: auto;
      overflow-y: auto;
    }
  }


  @media screen and (max-width: 950px) {
    .tableContainer{
      font-size: 0.8rem;
      .tableHeaderRow th {
        padding: 2px;
      }
      
      .tableBodyRow:hover {
        background-color: #f9f9f9;
      }
      
      .tableRowCell {
        padding: 0px;
      }
    }
  }
  @media screen and (max-width: 850px) {
    .tableContainer{
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 770px) {
    .tableContainer{
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 570px) {
    .tableContainer{
      font-size: 0.7rem;
    }
  }
  @media screen and (max-width: 500px) {
    .tableContainer{
      font-size: 0.6rem;
    }
    
    
  }
  
.nofeedbackContainer{
  font-size: 0.9rem;
  font-weight: bold;
  
}


.card_content_startTIme{
  width: 100%;
display: flex;
justify-content: end;
position: relative;
bottom: 7%;


div {
  background-color: #47529a;
  color: #ccc;
  padding: 5px 10px;
  border-radius: 10px;
  width: 300px;
  position: relative;
  overflow: hidden;
}



}




.activeContainer{
  margin:30px 0px;
  width: 95%;
  background-color: #fff;
  display: flex;
  min-height: 70px;
  justify-content: center;

  align-items: center;
  
  border: 2px solid #6368C0;
  border-radius: 10px;
  div{
    
  cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;

    align-items: center;
    font-size: 1rem;
width: 20%;
text-align: center;
padding: 5px 10px;
  }
  .activefeed{
    color: #6368C0;
    border-bottom: 3px solid  #6368C0;
    


  }
  
}