.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    align-self: center;
    margin-top: 2.5rem;
    .head{
        .heading{
            font-weight: 800;
            color: #454545;
            text-align: center;
            font-size: 1.8rem;
            padding: 0.5rem 0rem;
        }
    }
    .form{
        width: 70%;
        display: flex;
        flex-direction: column;

    }
    .field{
        display: flex;
        padding: 0.8rem;
        border: 1px solid #B8B8B8;
        border-radius: 6px;
        margin-top: 2rem;
        input{
            border: none;
            padding-left: 1rem;
            color: #666;
            font-size: 1rem;
            font-weight: 400;
            width: 100%;
            background-color: #EFF1F7;
            &:focus{
                outline: none;
            }
        }
        .eyeopen{
            &:hover{
                cursor: pointer;
            }
        }
    }
    .forgot{
        font-size: 0.9rem;
        color: #000;
        text-align: right;
        padding: 1rem 0rem 0rem 0rem;
        &:hover{
            color: #6C74CA;
        }
    }
    .loginbtn{
        border-radius: 3rem;
        background: #6C74CA;
        padding: 1rem 3rem;
        border: none;
        align-self: center;
        width: 10rem;
        color: white;
        margin-top: 2rem;
        font-weight: 600;
        &:hover{
            cursor: pointer;
            background-color: #4e55a5;
        }
    }
}
.invalid{
    font-size: 1rem;
    color: red;
    margin-bottom: -1rem;
}
.loginContainer{
    height: fit-content;
    width: 30rem;
    background-color: #FFFFFF;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

    .loginText{
        padding: .7rem 0rem;
        border-radius: 40px 40px 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6C74CA;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
    }
    .loginContent{
        margin: 2rem 0rem 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p{
            font-size: calc(2rem + .5vw);
            font-weight: 700;
            margin: .5rem 0 1.8rem 0;
            color: #333333;
        }
        img{
            height: 94px;
            width: 94px;
        }
        .GoogleLogin{
            margin: 0 1rem;
            margin-bottom: 2px;
        }
        .loginUnsucess{
            font-size: .8rem;
            font-weight: 400;
            color: #E33629;
        }
    }
}
.loginContainer{
    height: fit-content;
    width: 30rem;
    background-color: #FFFFFF;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

    .loginText{
        padding: .7rem 0rem;
        border-radius: 40px 40px 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6C74CA;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
    }
    .loginContent{
        margin: 2rem 0rem 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p{
            font-size: calc(2rem + .5vw);
            font-weight: 700;
            margin: .5rem 0 1.8rem 0;
            color: #333333;
        }
        img{
            height: 94px;
            width: 94px;
        }
        .GoogleLogin{
            margin: 0 1rem;
            margin-bottom: 2px;
        }
        .loginUnsucess{
            font-size: .8rem;
            font-weight: 400;
            color: #E33629;
        }
    }
}