.right{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
   .links{
    margin: 2rem 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
        display: none;
    }
   }
   @media screen and (max-width: 1024px) {
    height: fit-content;
}
}

.right::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width: 1024px) {
   .links{
        display: none;
    }
    
}