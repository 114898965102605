.newpass{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    .field{
        display: flex;
        padding: 0.8rem;
        img{
            padding-left: 1rem;
        }
        .eyeopen{
            &:hover{
                cursor: pointer;
            }
        }
    }
    .notmatch{
        margin: 0rem;
        color: red;
    }
    .errorPopup{
        display: flex ;
        flex-direction: column;
        backdrop-filter: blur(5px);
        box-shadow: 4px 4px 20px 20px rgba(255, 255, 255, 0.8);
        border: 1px solid #d2d1d1;
        border-radius: 1rem;
        max-width: 50vw;
        height: fit-content;
        padding: 2rem 3rem;
        position: absolute;
        align-items: center;
        justify-content: center;
        span{
            font-weight: 600;
            font-size: 1rem;
            padding-top: 1rem;
        }
    }
}