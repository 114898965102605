.box{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.box .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box .content .percent {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .box .content .percent::before {
    content: attr(data-text);
    position: absolute;
    inset: 20px;
    // background-image: Url("../../assets/student.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.75rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
  
  .box .content .percent svg {
    position: relative;
    width: 100px;
    height: 100px;
    transform: rotate(270deg);
  }
  
  .box .content .percent svg circle {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke-width: 2;
    stroke: rgba(0,0,0,0.05);
    transform: translate(5px, 5px);
  }
  .box .content .percent .dot {
    position: absolute;
    inset: 5px;
    z-index: 10;
    animation: animateDot 2s linear infinite;
  }
  
  @keyframes animateDot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .box .content .percent .dot::before {
    content: '';
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background: #999;
    border-radius: 50%;
  }