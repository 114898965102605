.features{
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: scroll;
    p{
        font-size: calc(1rem + 1.25vw);
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .cards{
        display: flex;
    }
    .head{
        .menu{
            img{
                filter: brightness(0%);
                float: left;
                margin: 2rem 1rem;
            }
        }
        .menu:hover{
            cursor: pointer;
        }
    }
}
.features::-webkit-scrollbar{
    display: none;
}