.container{
    display: flex;
   justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow-y: scroll;
    .left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60vw;
        height: 100vh;
        background: #636BC1;
        order: 1;
    }
    .right{
        width: 40vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1;
    }
    .links{
        display: none;
        width: 100%;
        
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: unset;
        .right{
            padding: 3rem 0rem;
            width: 100vw;
            order: 1;
        };
        .left{
            padding: 3rem 0rem;
            width: 100vw;
            order: 2;
        }
        .links{
            display: flex;
            align-items: center;
            justify-content: center;
            order: 3;
        }
    }
}
.container::-webkit-scrollbar{
    display: none;
}